<template>
  <div class="main">
    <div class="mainBox box box_column">
      <!-- <menuShow></menuShow> -->
      <navTitle class="nav"></navTitle>
      <div class="flex1 box box_column" :class="{ routerBoxIframe: getBreadcrumb && getBreadcrumb[0] && getBreadcrumb[0].name == '施工总览', routerBox: getBreadcrumb[0].name != '施工总览' }">
        <div v-if="getBreadcrumb && getBreadcrumb[0] && getBreadcrumb[0].name != '总览' && getBreadcrumb[0].name != '施工总览'">
          <div class="title_top">
            <!--  v-if="getBreadcrumb && getBreadcrumb.length" -->
            <!-- <el-breadcrumb>
              <el-breadcrumb-item v-for="(item, index) in getBreadcrumb" :key="index"
                >{{ item.name }}
                <i v-show="index < getBreadcrumb.length - 1" style="margin-left: 10px">&gt</i>
              </el-breadcrumb-item>
            </el-breadcrumb> -->
          </div>
        </div>
        <router-view v-if="!$route.meta.routeNone && isRouterAlive" style="height: 100%"  :key="$route.path + $route.query.t"></router-view>

        <!-- <keep-alive>
          <router-view v-if="$route.meta.keepAlive && isRouterAlive" style="height: 100%"  :key="$route.path + $route.query.t"></router-view>
        </keep-alive> -->
        <operationMaintenance v-show="$route.path == '/operationMaintenance'"> </operationMaintenance>
      </div>
    </div>
    <div v-if="show != 1" class="bg"></div>
    <iframe :class="show == 1 ? 'up' : 'down'" id="ditu" :src="src" width="100%" height="100%"></iframe>

    <!-- <iframe  :class="show == 2?'up1':'down'"   id="u3d" src="http://hwbcshow.cloudansys.com/unity3D/" width="100%" height="100%"></iframe> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import menuShow from "../components/allView/menuShow";
import navTitle from "../components/allView/navTitle.vue"; //文字导航
import { BREADCRUMB } from "../store/const";
import operationMaintenance from "@/views/operationMaintenance/operationMaintenance.vue"
export default {
  name: "alone",
  components: {
    menuShow,
    navTitle,
    operationMaintenance
  },
  watch: {
    $route(to, from) {
      console.log(999, to.path);
      var path = to.path;
      if (path == "/main" || path == "/allView" || path == "/home") {
        this.show = 1;
      } else {
        this.show = 0;
      }
      if (path == "/operationMaintenance") {
        this.isLoad3d = true;
      } else {
        this.isLoad3d = false;
      }
    },
    getBreadcrumb: {
      handler(n, o) {
        console.log("面包屑导航变化", n, "o", o);
        sessionStorage.setItem(BREADCRUMB, JSON.stringify(n));

        return n;
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      getBreadcrumb: "getBreadcrumb",
    }),
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isLoad3d:false,
      isRouterAlive: true,
      show: 0,
      src: "", //gis路径配置
      //正式
      //   src:'https://www.thingjs.com/pp/4ace010ee17c0cb185ee8dcd',     //测试
    };
  },
  //创建
  //   created(){
  //        this.$bus.$on('changeModel', this.getModel)
  //   },

  //安装
  mounted() {
    this.src = this.srcPATH; // ***********gis路径配置
    console.log(this.$route.path);
    var path = this.$route.path;
    if (path == "/main" || path == "/allView" || path == "/home") {
      this.show = 1;
    } else {
      this.show = 0;
    }
  },

  //方法
  methods: {
    // 获取bus传值
    getModel(e) {
      console.log("e 3D", e);
      this.show = e;
    },
    reload() {
      console.log("执行重新加载");
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  position: relative;
  overflow: hidden;
}

.nav {
  position: absolute;
  top: 0;
  z-index: 19;
}

.title_top {
  height: 52px;
  // margin-bottom: 13px;
  // background-image: linear-gradient(180deg, #02133a00 0%, #062160d4 86%);
  padding-left: 20px;
  // padding-top: 13px;
}

.routerBox {
  height: 100%;
  margin-top: 85px;
  // z-index: 1;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid #0c3380;
  border-top: 0;
  padding: 0 20px;
  padding-bottom: 20px;
}

.routerBoxIframe {
  // z-index: 1;
  padding-top: 61px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
}

.mainBox {
  width: 100%;
  height: 100%;
}

#ditu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  // z-index: 0;
}

#u3d {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  // z-index: 0;
}

.bg {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  // background: url("../assets/images/img/zhushijue_bg.png");    // 背景图
  // background-color: #000e28;
  background: linear-gradient(to top, #001f50, #001235);
  background-size: 100% 100%;
}

.up {
  z-index: 0;
}

.up1 {
  z-index: 999;
}

.down {
  z-index: -2;
}
</style>
